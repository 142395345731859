import { useEffect, useState } from 'react'

import Header from '../components/header';
import Footer from '../components/footer';
import { useSelector } from 'react-redux';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
// import { ILogin } from '../services/types/user.types';
import { IAdressRegister, ICustomerRegister } from '../services/interfaces/user.interface';
import { useAppDispatch } from '../core/store';
import { selectisconnected } from '../core/store/slices/authSlice';
import { useNavigate } from 'react-router';
import { selectCustomerDetails } from '../core/store/slices/userSlice';
import SaveAddressModal from './components/saveAddressModal.component';
import userServices from '../services/user.services';
import authService from '../services/auth.service';
import localStorageService from '../services/localStorage.service';
import { isConnectedChecked } from '../functions/connected.check';

function MonCompte() {
  // const [token, setToken] = useState('')
  const infoUser = useSelector(selectCustomerDetails)
  const [resetPasswordData, setResetPasswordData] = useState({ password: '', password_confirmation: '' })
  const [signupData, setSignupData] = useState<ICustomerRegister>({ customer_firstname: '', customer_lastname: '', customer_gender: 'H', customer_country: 'Togo', customer_birthdate: '', customer_email: '', customer_password: '', customer_password_confimation: '' })
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const connected = useSelector(selectisconnected)
  const [address, setAddress] = useState<IAdressRegister>()
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  isConnectedChecked(dispatch)
    .then(response => {
      if (response === false) {
        navigate('/connexion?')
      }
    })

  const resetPassword = async () => {
    dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    if (!(resetPasswordData.password && resetPasswordData.password_confirmation)) {
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
        dispatch(setNotification({ type: 'error', message: 'Vos champs sont vides', visible: true }))
      }, 3000)
      return
    }
    if (resetPasswordData.password !== resetPasswordData.password_confirmation) {
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
        dispatch(setNotification({ type: 'error', message: 'Vos mots mot de passe ne correspondent pas', visible: true }))
      }, 3000)
    }
    else {
      const token = await localStorageService.get('MeeMarketCustomerAccessToken');
      authService.changedPassword(token as string, resetPasswordData.password).then(
        (response) => {
          if (response.changed) {
            setTimeout(() => {
              dispatch(setIsLoading(false))
              document.body.style.overflow = 'visiible';
              dispatch(setNotification({ type: 'success', message: 'Mot de passe changé avec succès', visible: true }))
              setResetPasswordData({ password: '', password_confirmation: '' })
              navigate('/account');
            }, 5000)
          }
          else {
            setTimeout(() => {
              dispatch(setIsLoading(false))
              document.body.style.overflow = 'visible';
              dispatch(setNotification({ type: 'error', message: 'Quelques chose s\'est mal passé', visible: true }))
            }, 5000)
          }
        }
      ).catch((err) => {
        setTimeout(() => {
          dispatch(setIsLoading(false))
          document.body.style.overflow = 'visible';
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
        }, 3000)
      })
    }

  }

  useEffect(() => {
    document.title = 'Mon compte';
    if (connected) {
      userServices.getDefaultAdress()
        .then(response => {
          if (response.adresseName) {
            setAddress(response);
          }
        })
        .catch(err => {
          dispatch(setNotification({ type: 'info', message: 'Pas d\'adresse enrégistrée', visible: true }));
        });
    }
  }, [connected, dispatch]);

  return (
    <div>
      <Header />

      <SaveAddressModal
        isOpen={isModalOpen}
        onClose={closeModal}
      />
      <div className="container" style={{ paddingBottom: 60 }}>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="title text-center"> <strong>Informations sur Mon compte</strong></h2>
          </div>

          <div className='col-sm-6 mx-auto d-flex align-items-center'>
            <div className="bill-to">
              <div className="signup-form mx-auto d-flex align-items-center">
                <p>Mes informations</p>

                <form action="">
                  <h5>Nom</h5>
                  <input type="text" disabled value={infoUser.firstName} onChange={(value) => { setSignupData({ ...signupData, customer_firstname: value.target.value }) }} />
                  <h5>Prénom(s)</h5>
                  <input type="text" disabled value={infoUser.lastName} onChange={(value) => { setSignupData({ ...signupData, customer_lastname: value.target.value }) }} />
                  <h5>Date de naissance</h5>
                  <input type="date" disabled value={infoUser.birthdate} onChange={(value) => { setSignupData({ ...signupData, customer_birthdate: value.target.value }) }} />
                  <h5>Genre</h5>
                  <select disabled name="gender" id="gender" value={infoUser.gender} onChange={(value) => { setSignupData({ ...signupData, customer_gender: value.target.value }) }}>
                    <option value="H">Homme</option>
                    <option value="F">Femme</option>
                  </select>
                  <h5>Pays</h5>
                  <select disabled name="country" id="country" value={signupData.customer_country} required onChange={(value) => { setSignupData({ ...signupData, customer_country: value.target.value }) }}>
                    <option value="Togo">Togo</option>
                  </select>
                  <h5>Adresse mail</h5>
                  <input type="email" disabled placeholder="Adresse email" value={infoUser.email} required onChange={(value) => { setSignupData({ ...signupData, customer_email: value.target.value }) }} />
                  <button type="submit" className="btn btn-default p-3 mt-4" onClick={() => { }}>Modifier</button>
                </form>
              </div>{/*/sign up form*/}
            </div>
          </div>
          <div className="col-sm-4 col-sm-offset-1">
            {/* <p>Changer de mot de passe</p> */}
            <div className="bill-to">
              <div className="login-form">{/*login form*/}
                <p>Changer de mot de passe</p>
                <form action="#">
                  <h5>Nouveau Mot de passe</h5>
                  <input type="password" placeholder="Mot de passe" required value={resetPasswordData.password} onChange={(value) => { setResetPasswordData({ ...resetPasswordData, password: value.target.value }) }} />
                  <h5>Confirmer votre mot de passe</h5>
                  <input type="password" placeholder="Confirmer votre mot de passe" value={resetPasswordData.password_confirmation} required onChange={(value) => { setResetPasswordData({ ...resetPasswordData, password_confirmation: value.target.value }) }} />
                  <button type="submit" className="btn btn-default p-3 mt-4" style={{ marginBottom: '10px' }} onClick={resetPassword}>Modifier</button>
                </form>
              </div>


            </div>{/*/login form*/}
            <div className="bill-to">
              <div className="login-form">
                <p>Adresse enregistrée</p>
                {

                  address ?
                    <>
                      <div className="form-one">
                        <form>
                          <label>Adresse</label>
                          <input type="text" placeholder="Address 1 *" value={address?.adresseName} disabled />
                          <label>Numéro de téléphone</label>
                          <input type="text" placeholder="Mobile Phone" value={address?.phoneNumber} disabled />
                          <label>Numéro whatsapp</label>
                          <input type="text" placeholder="Numero whatsapp" value={address?.whatsappPhoneNumber} disabled />
                        </form>
                      </div>
                      <div className="form-two">
                        <form>
                          <label>Code postal</label>
                          <input type="text" placeholder="Zip / Postal Code *" value={address?.postalBox} disabled />
                          <label>Ville</label>
                          <select>
                            <option>{address?.city}</option>
                          </select>
                          <label>Rue</label>
                          <input type="text" placeholder="Street" value={address?.street} disabled />
                        </form>
                      </div>
                    </>
                    :
                    <h2>Pas d'adresse</h2>
                }
              </div>
              <button type='submit' style={{ backgroundColor: '#3879CD', color: '#fff', marginRight: '250px' }} className="btn btn-default p-3 mt-4" onClick={openModal} > {address ? 'Modifier votre adresse' : 'Enrégistrer une Adresse'}</button>
            </div>


            {/* <div className=''><button className="btn mt-5" onClick={openModal} >Utiliser une Adresse par défaut</button></div> */}
            {/* <div className="col-sm-1">
              <h2 className="or">OU</h2>
            </div> */}
          </div>
        </div>
      </div >
      <Footer />
    </div >
  )
}

export default MonCompte

