import { JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react'
import ProductCard from '../components/customCard';
import Header from '../components/header';
import Footer from '../components/footer';
import Pagination from 'react-bootstrap/Pagination';
import { LeftDiv } from '../components/LeftDiv.component';
import productsService from '../services/products.service';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import { useDispatch } from 'react-redux';
import CustomCard from '../components/customCard';
import { useParams } from 'react-router-dom';
import { environement } from '../environements/environement';

const SearchProduct = () => {
  const { productName } = useParams();
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  useEffect(() => {
    dispatch(setIsLoading(true))
    document.title = 'Mee Market';
    document.body.style.overflow = 'hidden';
    productsService.searchProduct(productName as string).then(response => {
      // console.warn(response)
      setData(response)
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
      }, 500)
    }).catch(err => {
      // console.warn(err)
      dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
      dispatch(setIsLoading(false))
      document.body.style.overflow = 'visible';
    })
  }, [dispatch, productName]);


  const [currentPage, setCurrentPage] = useState(1)
  const articlesPerPage = environement.product_per_page; // Nombre d'articles par page
  const lastIndex = currentPage * articlesPerPage;
  const firstIndex = lastIndex - articlesPerPage;

  const displayedArticles = data.slice(firstIndex, lastIndex);
  const npage = Math.ceil(data.length / articlesPerPage)
  // const numbers = [Array(npage + 1).keys()].slice(1)
  const numbers = Array.from({ length: npage }, (_, i) => i + 1);
  console.log(numbers);



  useEffect(() => {
    document.title = 'Shop';
  }, []);

  const nextPage = () => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <Header />
      <section id="advertisement">
        <div className="container">
          <img src={require('../assets/images/shop/banner.png')} alt="" />
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <LeftDiv />
            <div className="col-sm-9 padding-right">
              <div className="features_items">{/*features_items*/}
                <h2 className="title text-center">RECHERCHE POUR '{productName}'</h2>
                {
                  displayedArticles.length === 0 ?
                    <div className="text-center" style={{ marginTop: 150 }}>
                      <h4>Aucun produit trouvé</h4>
                    </div>
                    :
                    displayedArticles.map((value, i) =>
                      <CustomCard data={value} key={i} />
                    )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        displayedArticles.length &&
        <div className="text-center">
          <ul className="pagination">
            <li className={currentPage === 1 ? 'disabled' : ''}>
              <a href="#" onClick={prevPage}>Pre</a>
            </li>
            {numbers.map((number) => (
              <li
                className={currentPage === number ? 'active' : ''}
                key={number}
              >
                <a href="#" onClick={() => setCurrentPage(number)}>
                  {number}
                </a>
              </li>
            ))}
            <li className={currentPage === npage ? 'disabled' : ''}>
              <a href="#" onClick={nextPage}>Suiv</a>
            </li>
          </ul>
        </div>
      }
      <Footer />
    </div>

  )
}


export default SearchProduct



