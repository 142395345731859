export const environement = {
    product_per_page: 12,
    shop_product_per_page: 12,
    // baseUrl: "http://35.203.155.117:9000/api/customer",
    // productImagePath: "http://35.203.155.117:9000/products/",
    // baseUrl: "http://localhost:9000/api/customer",
    // productImagePath: "http://localhost:9000/products/"
    baseUrl: "https://api.mee-market.com/api/customer",
    productImagePath: "https://api.mee-market.com/products/"
}
