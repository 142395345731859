import Badge from 'react-bootstrap/Badge';
import { useState } from "react";
import authService from "../services/auth.service";
import { useAppDispatch } from "../core/store";
import { setIsLoading, setNotification } from "../core/store/slices/loadingSlice";
import { useSelector } from "react-redux";
import { selectCard, setCards } from "../core/store/slices/cardSlice";
import { useNavigate } from 'react-router-dom';
import localStorageService from '../services/localStorage.service';
import { selectCustomerDetails } from '../core/store/slices/userSlice';
import { userInfo } from 'os';

export default function Header() {
    const infoUser = useSelector(selectCustomerDetails)
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [productName, setProductName] = useState('')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem('MeeMarketCustomerAccessToken');
    const search = () => {
        navigate(`/Search-product/${productName}`)
    }
    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    const logout = async () => {
        dispatch(setIsLoading(true));

        try {
            await authService.logout().then(
                response => {
                    setTimeout(() => {
                        dispatch(setCards([]));
                        localStorageService.set('cards', JSON.stringify([]));
                        dispatch(setIsLoading(false))
                        dispatch(setNotification({ type: 'success', message: 'Déconnexion avec succès', visible: true }))
                        navigate('/connexion?');
                    }, 5000)
                }
            ).catch((err) => {
                dispatch(setIsLoading(false))
                dispatch(setNotification({ type: 'error', message: err, visible: true }))
            })
        } catch (err) {
            // console.warn(err)
            dispatch(setNotification({ type: 'error', message: `Error ${err}`, visible: true }))
        }
    }

    return (
        <header id="header">{/*header*/}
            <div className="header_top">{/*header_top*/}

            </div>{/*/header_top*/}
            <div className="header-middle">{/*header-middle*/}
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="logo pull-left">
                                <a href="/"><img src={require('../assets/images/mee/logo.png')} style={{ width: '139px', height: '39px' }} alt='' /></a>
                            </div>
                            {/* <div className="btn-group pull-right">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default dropdown-toggle usa" data-toggle="dropdown">
                                        USA
                                        <span className="caret"></span>
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a href="#">Canada</a></li>
                                        <li><a href="#">UK</a></li> 
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-sm-8">
                            <div className="shop-menu pull-right">
                                <ul className="nav navbar-nav">
                                    <li><a href="/check-order"><i className="fa fa-crosshairs" /> Vérifier une commande</a></li>
                                    <li><a href="/cards"><i className="fa fa-shopping-cart" /> Panier  <Badge style={{ backgroundColor: 'red' }} bg="danger">{useSelector(selectCard).length}</Badge></a></li>
                                    {isLoggedIn ? null : <li><a href="/connexion?"><i className="fa fa-lock" /> Connexion/ Inscription</a></li>}
                                    {isLoggedIn ? <li><a href="/orders-list"><i className="fa fa-tag" /> Mes commandes</a></li> : null}
                                    {isLoggedIn ? <li><a style={{
                                        marginLeft:'10px',
                                        width: `100px`,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }} href="/account"><i className="fa fa-user" />{infoUser.firstName + ' ' + infoUser.lastName}</a></li> : null}
                                    {isLoggedIn ? <li><a href="" onClick={() => { logout() }}><i className="fa fa-sign-out" />Se déconnecter</a></li> : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" style={{ backgroundColor: 'black' }} onClick={toggleNav}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                </button>
                            </div>
                            <div className="mainmenu pull-left">
                                <ul className={` ${isNavOpen ? 'nav navbar-nav navbar-collapse in' : 'nav navbar-nav navbar-collapse collapse'}`} style={{ width: 'auto', height: 'auto' }}>
                                    <li><a href="/" className="active">Accueil</a></li>
                                    {/* <li><a href="404">Meilleurs ventes</a></li> */}
                                    <li className="dropdown"><a href="#">Produit<i className="fa fa-angle-down" /></a>
                                        <ul role="menu" className="sub-menu">
                                            {/* <li><a href="shop.html">Rechercher par catégorie</a></li> */}
                                            <li><a href="/shop"> Liste des produits</a></li>
                                            <li><a href="/check-order">Vérifier une commande</a></li>
                                            {isLoggedIn ? <li><a href="/orders-list"> Mes commandes</a></li> : null}
                                            <li><a href="/cards">Panier</a></li>
                                        </ul>
                                    </li>
                                    {/* <li className="dropdown"><a href="#">Support<i className="fa fa-angle-down" /></a>
                                        <ul role="menu" className="sub-menu">
                                            <li><a href="blog.html">Service client 24H/7J</a></li>
                                            <li><a href="blog-single.html">Faire une réclamation</a></li>
                                            <li><a href="blog-single.html">Donner votre avis</a></li>
                                        </ul>
                                    </li> */}
                                    <li><a href="/apropos">A propos</a></li>
                                    <li><a href="/contact-us">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="search_box pull-right">
                                <input type="text" value={productName} onChange={(value) => { setProductName(value.target.value) }} placeholder="Rechercher un article" />
                                <button type="submit" className="btn btn-default" onClick={search}><i className="fa fa-search" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}