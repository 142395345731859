import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Header from '../components/header';
import Footer from '../components/footer';
import authService from '../services/auth.service';
import { ILogin } from '../services/types/user.types';
import { isValidEmail } from '../functions/email.verifi';
import localStorageService from '../services/localStorage.service';
import { setIsConnected } from '../core/store/slices/authSlice';
import { useAppDispatch } from '../core/store';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import { ICustomerRegister } from '../services/interfaces/user.interface';
import EmailModal from './components/emailModal.component';

const LoginSignUp = () => {
  const [email, setEmail] = useState('')
  const [loginData, setLoginData] = useState<ILogin>({ email: '', password: '' })
  const [signupData, setSignupData] = useState<ICustomerRegister>({ customer_firstname: '', customer_lastname: '', customer_gender: 'H', customer_country: 'Togo', customer_birthdate: '', customer_email: '', customer_password: '', customer_password_confimation: '' })
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false)
  const [actionType, setActionType] = useState('')

  const handleSubmit = async () => {
    // console.warn('Clicked')\
    if (isValidEmail(email)) {
      dispatch(setIsLoading(true))
      if (actionType === 'RESEND') {
        // console.warn(email, 'action resend')
        await authService.resentVerifyMail(email)
          .then(response => {
            setVisible(false)
            if (response.exist === false) {
              setTimeout(() => {
                setVisible(false)
                dispatch(setIsLoading(false))
                dispatch(setNotification({ type: 'error', message: 'Ce compte n\'existe pas', visible: true }))
              }, 3000)
            } else {
              setVisible(false)
              dispatch(setIsLoading(false))
              dispatch(setNotification({ type: 'success', message: 'Email de vérification envoyé avec success', visible: true }))
            }
          })
          .catch(err => {
            setTimeout(() => {
              setVisible(false)
              dispatch(setIsLoading(false))
              dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
            }, 3000)
          })
      } else if (actionType === 'RESET') {
        // console.warn(email, 'action reset')
        await authService.sendResetMail(email)
          .then(response => {
            setVisible(false)
            if (response.exist === false) {
              setTimeout(() => {
                setVisible(false)
                dispatch(setIsLoading(false))
                dispatch(setNotification({ type: 'error', message: 'Ce compte n\'existe pas', visible: true }))
              }, 3000)
            } else {
              setVisible(false)
              dispatch(setIsLoading(false))
              dispatch(setNotification({ type: 'success', message: 'Email de vérification envoyé avec success', visible: true }))
            }
          })
          .catch(err => {
            setTimeout(() => {
              setVisible(false)
              dispatch(setIsLoading(false))
              dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
            }, 3000)
          })
      }
    } else {
      dispatch(setNotification({ type: 'error', message: 'Adresse email non valide', visible: true }))
    }
  }

  const handleOnChangeEmail = (value: string) => {
    // console.warn(value)
    setEmail(value)
  }

  const onRequestClose = () => {
    setVisible(false)
  }

  const openMailModal = (type: string) => {
    setEmail('')
    setVisible(true)
    setActionType(type)
  }


  const login = async () => {
    if (isValidEmail(loginData.email)) {
      dispatch(setIsLoading(true))
      document.body.style.overflow = 'hidden';
      await authService.login(loginData.email, loginData.password).then(
        (response) => {
          // console.warn(response)
          if (response.accessToken) {
            dispatch(setIsConnected(true))
            localStorageService.set("MeeMarketCustomerAccessToken", response.accessToken)
            localStorageService.set("MeeMarketCustomerAccessTokenExpire", response.accessTokenExpire)
            localStorageService.set("MeeMarketCustomerRefreshToken", response.refreshToken)
            setTimeout(() => {
              dispatch(setNotification({ type: 'success', message: 'Nous sommes ravis de vous revoir', visible: true }))
              dispatch(setIsLoading(false))
              navigate('/');
            }, 5000)
          }
          if (response.verified === false) {
            dispatch(setIsLoading(false))
            document.body.style.overflow = 'visible';
            dispatch(setNotification({ type: 'warn', message: 'Email non vérifié', visible: true }))
          }
        }
      ).catch((err) => {
        // console.warn(err)
        setTimeout(() => {
          dispatch(setIsLoading(false))
          document.body.style.overflow = 'visible';
          dispatch(setNotification({ type: 'error', message: 'Email ou mot de passe incorrecte', visible: true }))
        }, 3000)
      })
    }
  }

  const signUp = () => {
    dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    if (!(signupData.customer_birthdate && signupData.customer_email && signupData.customer_firstname && signupData.customer_country && signupData.customer_gender && signupData.customer_lastname && signupData.customer_password)) {
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
        console.warn(signupData)
        dispatch(setNotification({ type: 'error', message: 'Vos champs sont vides', visible: true }))
      }, 3000)
      return
    }
    if (signupData.customer_password !== signupData.customer_password_confimation) {
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow  = 'visible';
        dispatch(setNotification({ type: 'error', message: 'Vos mots mot de passe ne correspondent pas', visible: true }))
      }, 3000)
    }
    else {
      authService.register(signupData).then(
        (response) => {
          if (response.customer_id) {
            dispatch(setIsLoading(false))
            document.body.style.overflow = 'visiible';
            dispatch(setNotification({ type: 'success', message: 'Nous sommes heureux de vous compter parmis nous, Vous allez recevoir un mail de vérification', visible: true }))
            navigate('/')
          }
          else if (response.alreadyExist) {
            setTimeout(() => {
              dispatch(setIsLoading(false))
              document.body.style.overflow = 'visible';
              dispatch(setNotification({ type: 'error', message: 'Ce compte existe deja', visible: true }))
            }, 5000)
          }
        }
      ).catch((err) => {
        setTimeout(() => {
          dispatch(setIsLoading(false))
          document.body.style.overflow = 'visible';
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
        }, 3000)
      })
    }
  }
  return (
    <div>
      <Header />
      <EmailModal
        action={actionType}
        email={email}
        isOpen={visible}
        onRequestClose={onRequestClose}
        handleOnChange={handleOnChangeEmail}
        handleOnClick={handleSubmit}
      />
      <div className="container" style={{ paddingBottom: 60 }}>
        <div className="row">
          <div className="col-sm-4 col-sm-offset-1">
            <div className="login-form">
              <h2>Connectez-vous à votre compte</h2>
              <form action="#">
                <h5>Adresse email</h5>
                <input type="email" placeholder="Adresse email" value={loginData.email} required onChange={(value) => { setLoginData({ ...loginData, email: value.target.value }) }} />
                <h5>Mot de passe</h5>
                <input type="password" placeholder="Mot de passe" required value={loginData.password} onChange={(value) => { setLoginData({ ...loginData, password: value.target.value }) }} />
                <span style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                  <a href="#" onClick={() => openMailModal('RESET')}>Mot de passe oublié</a>
                  <a href="#" onClick={() => openMailModal('RESEND')}>Renvoyer l'email de vérification</a>
                </span>
                <button type="submit" className="btn btn-default" onClick={login}>Se connecter</button>
              </form>
            </div>{/*/login form*/}
          </div>
          <div className="col-sm-1">
            <h2 className="or">OU</h2>
          </div>
          <div className="col-sm-4">
            <div className="signup-form">{/*sign up form*/}
              <h2>Nouvel utilisateur, Incrivez-vous!</h2>
              <form action="#">
                <h5>Nom</h5>
                <input type="text" placeholder="Nom" value={signupData.customer_lastname} required onChange={(value) => { setSignupData({ ...signupData, customer_lastname: value.target.value }) }} />
                <h5>Prénom(s)</h5>
                <input type="text" placeholder="Prénom(s)" value={signupData.customer_firstname} required onChange={(value) => { setSignupData({ ...signupData, customer_firstname: value.target.value }) }} />
                <h5>Date de naissance</h5>
                <input type="date" placeholder="Date de naissance" value={signupData.customer_birthdate} required onChange={(value) => { setSignupData({ ...signupData, customer_birthdate: value.target.value }) }} />
                <h5>Genre</h5>
                <select value={signupData.customer_gender} required onChange={(value) => { setSignupData({ ...signupData, customer_gender: value.target.value }) }}>
                  <option value="H">Homme</option>
                  <option value="F">Femme</option>
                </select>
                <h5>Pays</h5>
                <select value={signupData.customer_country} required onChange={(value) => { setSignupData({ ...signupData, customer_country: value.target.value }) }}>
                  <option value="Togo">Togo</option>
                </select>
                <h5>Adresse mail</h5>
                <input type="email" placeholder="Adresse email" value={signupData.customer_email} required onChange={(value) => { setSignupData({ ...signupData, customer_email: value.target.value }) }} />
                <h5>Mot de passe</h5>
                <input type="password" placeholder="Mot de passe" value={signupData.customer_password} required onChange={(value) => { setSignupData({ ...signupData, customer_password: value.target.value }) }} />
                <h5>Confirmer votre mot de passe</h5>
                <input type="password" placeholder="Confirmer votre mot de passe" value={signupData.customer_password_confimation} required onChange={(value) => { setSignupData({ ...signupData, customer_password_confimation: value.target.value }) }} />
                <button className="btn btn-default" onClick={() => signUp()}>S'inscrire</button>
              </form>
            </div>{/*/sign up form*/}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LoginSignUp