import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/custom-carousel.css'

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true, // Active le défilement automatique
        autoplaySpeed: 2000,
    };

    return (
        <Slider {...settings} className="custom-carousel">
            <div>
                <img src={require('../assets/images/mee/mee-1.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-2.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-3.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-1.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-2.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-3.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-1.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-2.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            <div>
                <img src={require('../assets/images/mee/mee-3.jpg')} className="girl img-responsive" alt="Mee Market" />
            </div>
            {/* Ajoutez d'autres éléments selon le nombre d'images que vous souhaitez afficher */}
        </Slider>
    );
};

export default Carousel;
